@import-normalize;

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./assets/fonts/nunito-300.woff) format('woff'),
    url(./assets/fonts/nunito-300.woff2) format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./assets/fonts/nunito-regular.woff) format('woff'),
    url(./assets/fonts/nunito-regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: local('Nunito'), url(./assets/fonts/nunito-700.woff) format('woff'),
    url(./assets/fonts/nunito-700.woff2) format('woff2');
    font-weight: 700;
    font-style: normal;
}

a {
    text-decoration: none;
    outline: 0;
}

.g-table-header thead tr th {
    padding-top: 0;
    padding-bottom: 0;
}

.g-table-header thead tr th:last-child {
    text-align: right;
}

.g-table-body-last-col-right tbody td:last-child {
    text-align: left !important;
}

.g-table-no-header thead {
    display: none;
}
.g-table-body-last-col-right tbody tr td , .g-table-body-last-col-right tbody tr th {
    background: transparent !important;
}

.g-table-header tbody tr td, .g-table-header tbody tr th  {
    background: transparent !important;
}

/* Tooltip override */
body.light > div > div[aria-hidden=false] > div > div {
    box-shadow: none;
    background: unset;
}

body.dark > div > div[aria-hidden=false] > div > div {
    box-shadow: none;
    background: unset;
}

table:not(.g-table-transactions) tr:hover {
    background: none;
}

table:not(.g-table-transactions) thead tr:first-child th, table:not(.g-table-transactions) thead tr:first-child td {
    border: none;
}

.g-table-transactions thead tr th {
    font-weight: 700;
}


